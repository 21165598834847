  <template>
  <div class="row mt-0">
    <div class="col-12">
      <div class="card mb-1">
        <div class="bg-light-primary rounded-top py-half px-1">
          <div class="psh-header mb-0 d-flex align-items-center px-0">
            <div class="psh-title border-0 mr-0">
              <i class="icon icon-psh-shipment text-h1" style="font-size: 2rem;" />
            </div>
            <h4 class="card-title mb-25 text-primary ml-1 flex-grow-1">
              Shipments
            </h4>
          </div>
        </div>
        <div class="border rounded-bottom">
          <div class="text-warning p-half m-1 rounded">
            <p-icon name="bi-exclamation-triangle" class="mr-half" />
            The
            <h6 class="d-inline">
              "Cancel Shipment"
            </h6>
             and
            <h6 class="d-inline">
              "Reopen Shipment"
            </h6>
               buttons will be active when the "Shipped" status is selected from the data grid filter.
          </div>
          <div class="row px-1 pb-1 mt-1">
            <div class="col-4 px-half">
              <dx-util-button :disabled="currentOrder.fulfillmentStatus === 'Unshipped'" class="d-block d-inline" type="danger" text="Cancel Shipment" @click="cancelShipment" />
            </div>
            <div class="col-4 px-half">
              <dx-util-button :disabled="currentOrder.fulfillmentStatus === 'Unshipped'" class="d-block d-inline" type="warning" text="Reopen Shipment" @click="reopenShipment" />
            </div>
            <div class="col-4 px-half">
              <dx-util-button :disabled="currentOrder.fulfillmentStatus === 'Shipped'" class="d-block d-inline" type="success" text="Mark As Shipped" @click="markAsShipped" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@core/utils/filter'
import { v4 as uuidv4 } from 'uuid'
import { Notify } from '@/@robustshell/utils'
import fbmService from '@/http/requests/fbm/fbmService'
import useFbmState from '../useFbmStates'

export default {
  components: {
  },
  setup() {
    const {
      currentOrder, reservedItems, orderShippingLabels, orderItems,
      preventShipmentSave, getShippingLabels, fbmOrderReloadNeeded,
      isCurrentOrderShipped, orderSelected, getFbmOrderItems, getFbmOrder,
      loadFbmStores, shippingLabelsArray,
    } = useFbmState()
    return {
      reservedItems,
      orderShippingLabels,
      isCurrentOrderShipped,
      currentOrder,
      orderItems,
      preventShipmentSave,
      getShippingLabels,
      getFbmOrderItems,
      getFbmOrder,
      fbmOrderReloadNeeded,
      orderSelected,
      loadFbmStores,
      shippingLabelsArray,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    shipments() {
      const result = this.orderShippingLabels?.flatMap(item => item.shipBox?.items.map(boxItem => ({
        id: item?.id,
        trackingNumber: item?.trackingNumber,
        shipmentDate: item?.shipDate,
        msku: boxItem?.msku,
        quantity: boxItem?.shipCount,
      }))).filter(el => el !== null && el !== undefined)
      return result ?? []
    },

  },
  async mounted() {
    await this.prepareShipmentsData()
  },
  methods: {
    async removeBox(labelId) {
      await fbmService.deleteBoxItems(labelId)
      await this.getShippingLabels(this.currentOrder.id)
    },
    formatDate(d) {
      return formatDate(d)
    },
    async markAsShipped() {
      if (this.reservedItems?.length === 0) {
        this.$swal({
          title: 'Do you want to mark as shipped without any reserved items?',
          text: 'Please note, this action will not decrease your inventory.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Mark as shipped',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((async res => {
          if (res.value) {
            await fbmService.markAsShipped(this.currentOrder.id)
            this.fbmOrderReloadNeeded = uuidv4()
            this.getFbmOrder(this.currentOrder.id)
            this.loadFbmStores()
            this.orderSelected = false
            this.currentOrder.id = null
          }
        }))
      } else {
        await fbmService.markAsShipped(this.currentOrder.id)
        this.fbmOrderReloadNeeded = uuidv4()
        this.getFbmOrder(this.currentOrder.id)
        this.loadFbmStores()
        this.orderSelected = false
        this.currentOrder.id = null
      }
    },
    reopenShipment() {
      this.$swal({
        title: 'Do you want to reopen this orders?',
        text: 'This action will change your order\'s status "AVAILABLE". This will not cancel label purchases or item reservations.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Reopen',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((async res => {
        if (res.value) {
          await fbmService.reopenShipment(this.currentOrder.id)
          this.fbmOrderReloadNeeded = uuidv4()
          this.getFbmOrder(this.currentOrder.id)
          this.loadFbmStores()
          this.orderSelected = false
          this.currentOrder.id = null
        }
      }))
    },
    async cancelShipment() {
      this.$swal({
        title: 'Are you sure you want to cancel this shipment?',
        text: 'This will cancel all purchased shipment labels & reservations.',
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Type CANCEL to confirm',
        showCancelButton: true,
        confirmButtonText: 'Yes, do the cancellation',
        cancelButtonText: 'No, close this popup.',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          if (result.value === 'CANCEL') {
            try {
              await fbmService.cancelShipment(this.currentOrder.id)
              this.$swal({
                icon: 'success',
                title: 'Shipment Cancellation',
                text: 'Shipment is cancelled',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              await this.getShippingLabels(this.currentOrder.id)
              this.fbmOrderReloadNeeded = uuidv4()
              this.getFbmOrder(this.currentOrder.id)
              this.orderSelected = false
              this.currentOrder.id = null
            } catch (err) {
              const messeage = err.message || err
              Notify.error(messeage)
            }
          } else {
            Notify.warning('The shipment is not cancelled. Please type "CANCEL" to confirm shipment cancellation')
          }
        }
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.text-button {
  margin-left: 16px;
  transition: color 0.3s ease;
}
.text-button:hover {
  color: #66bcf6;
}

.delete-btn {
  margin-left: 8px;
  margin-right: 8px;
  color: #e4388b;
  transition: color 0.3s ease;
}
.delete-btn:hover {
  color: #c56593;
}

th {
  background-color: #215271;
  color: rgb(181, 200, 227);
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 1px;
}

tr {
  font-size: 13px;
}

tr, td {
  padding: 8px;
  padding-bottom: 8px;
  text-align: center;
}

tr:first-child th:first-child {
  text-align: left;
  padding-left: 6px;
}

</style>
